import React, {useEffect, useState} from "react";
import './index.scss';
import axios from "axios";
import {SketchPicker} from 'react-color';
import config from "../../../config";
import ConfirmDelete from "../ConfirmDelete";
const Catalog = ({collection, setList, list}) => {
    const [edit, setEdit] = useState(false);
    const [stock, setStock] = useState();
    const [color, setColor] = useState();
    const [id, setId] = useState();
    const [colorPicker, setColorPicker] = useState(false);
    const [indexVal, setIndexVal] = useState();
    const [confirmDel, setConfirmDel] = useState(false);

    const [count, setCount] = useState([])

    // useEffect(() => {
    //     console.log(count, 'count');
    // }, [count]);


    useEffect(() => {
        // console.log(list, 'list')
        if (list) {
            const findModels = list.filter((val, index) => val.name === collection)
            setCount(findModels)
        }
    }, [list, collection]);

    useEffect(() => {
        axios.get(`${config.SERVER_ADDRESS}/api/posts/`)
            .then((response) => {
                setList(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
            .then(() => {});
    }, [])

    const deleteAll = () => {
        axios.delete(`${config.SERVER_ADDRESS}/api/delete/` + collection )
            .then((response) => {
                axios.get(`${config.SERVER_ADDRESS}/api/posts/`)
                    .then((response) => {
                        setList(response.data);
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .then(() => {});
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const deleteEl = (id) => {
        if (id) {
            axios.delete(`${config.SERVER_ADDRESS}/api/posts/${id}`)
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                })
                .then(() => {
                    axios.get(`${config.SERVER_ADDRESS}/api/posts`)
                        .then((response) => {
                            setList(response.data);
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                });
        }
    }

    return (
        <div className="catalog">
            {confirmDel && <ConfirmDelete setConfirmDel={setConfirmDel} deleteAll={deleteAll} />}
            <div className="upperBlock">
                <div className="firstBlock">
                    <div className="title id">
                        #ID
                    </div>
                    <div className="title">
                        Name
                    </div>
                </div>
                <div className="title stock">
                    Stock
                </div>
                <div className="secondBlock">
                    <div className="title id">
                        ID Model
                    </div>
                    <div className="title color">
                        Color
                    </div>
                    <div
                        className="deleteBtn"
                        onClick={() => {
                            setConfirmDel(true)
                        }}>
                        Delete All
                    </div>
                </div>
            </div>
            {count && count.map((val, index) => {
                if (val.name === collection) {
                    if (val.model[1] === 'deaconstoleFix.glb') {
                        console.log(val)
                    }
                    return (
                        <div key={index} className="item" >
                            <div style={{display: "flex"}}>
                                <div className="id">{index + 1}</div>
                                <div className="name">{val.model[1]}</div>
                            </div>

                            {edit && index === indexVal
                                ? <input className="editStock" type="number" onChange={(e) => {
                                    setStock(e.target.value);
                                }} />
                                // : <div className="stock">{val.stock}</div>
                                : <div className={`stock ${val.stock <= 0 && 'outStock'}`}>{val.stock <= 0 ? 'Out of stock' : val.stock}</div>
                            }

                            <div style={{display: "flex"}}>
                                {edit && index === indexVal
                                    ? <input className="editId" type="number" onChange={(e) => {
                                        setId(e.target.value);
                                    }} />
                                    : <div className="idModel">{val.id}</div>
                                    // : <div className={`id ${val.id <= 0 && 'outId'}`}>{val.id <= 0 ? 'invalid Id' : val.id}</div>
                                }
                                <div style={{position: "relative"}}>
                                    <div
                                        style={{background: val.color, cursor: `${edit && index === indexVal ? 'pointer' : ''}`}}
                                        className={`color`}
                                        onClick={() => {
                                            if (edit && index === indexVal) {
                                                setColorPicker(true)
                                            }
                                        }}
                                    />
                                    {colorPicker && index === indexVal &&
                                        <div style={{position: 'absolute', zIndex: "3", left: '-76px', top: '25px'}}>
                                            <SketchPicker
                                                color={color}
                                                onChange={(e) => {
                                                    setColor(e.hex)
                                                }}
                                            />
                                        </div>
                                        }
                                </div>
                                <div className="btnWrap">
                                    <div
                                        className="editBtn"
                                        onClick={() => {
                                            setEdit(true);
                                            setIndexVal(index)
                                            setColor(val.color)
                                            setStock(val.stock)
                                            if (edit) {
                                                setEdit(false);
                                                setIndexVal(null);
                                                setColorPicker(false)
                                                const data = {
                                                    stock: stock,
                                                    color: color,
                                                    id: id,
                                                    _id: val._id,
                                                }
                                                axios.put(`${config.SERVER_ADDRESS}/api/posts/${val._id}`, data)
                                                    .then((response) => {
                                                        axios.get(`${config.SERVER_ADDRESS}/api/posts/`)
                                                            .then((response) => {
                                                                // setList(response.data);
                                                                setList(response.data);
                                                            })
                                                            .catch((error) => {
                                                                console.log(error);
                                                            })
                                                            .then(() => {});
                                                    })
                                                    .catch((error) => {
                                                        console.log(error);
                                                    })
                                                    .then(() => {});
                                            }
                                        }}
                                    >
                                        {edit && index === indexVal ? 'Save' : 'Edit'}
                                    </div>
                                    <div className="deleteBtn"
                                         onClick={() => {
                                             deleteEl(val._id)
                                         }}
                                    >
                                        Delete</div>
                                </div>
                            </div>
                        </div>
                    )
                }
            })}
        </div>
    )
}

export default Catalog;
