import React, {useEffect, useState} from 'react';
import SelectCollection from "../SelectCollection/SelectCollection";
import SelectedCollection from "../SelectedCollection";
import config from '../../config';
import './index.scss'
import axios from 'axios';

const SelectModel = (
    {
        setLoading,
        setStep,
        setModelsConf,
        modelsConf,
        collection,
        setCollection,
    }) => {

    const [newModels, setNewModels] = useState([]);
    const [arr, setArr] = useState([]);
    const [previewLoad, setPreviewLoad] = useState(false);

    useEffect(() => {
        axios.get(`${config.SERVER_ADDRESS}/api/posts/`)
            .then((response) => {
                setArr(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
            .then(() => {});
    }, [])
    useEffect(() => {
        const findModels = arr.filter((val, index) => val.name === collection)
        setNewModels(findModels)
        setPreviewLoad(true);
    }, [collection, arr])

    return(
        <div className="wrapConfig">
            <div className="collectionWrapper">
                <SelectCollection
                    collection={collection}
                    setCollection={setCollection}
                    setStep={setStep}
                    modelsConf={modelsConf}
                    setModelsConf={setModelsConf}
                />
            </div>
            <SelectedCollection
                setPreviewLoad={setPreviewLoad}
                previewLoad={previewLoad}
                newModels={newModels}
                modelsConf={modelsConf}
                setModelsConf={setModelsConf}
                collection={collection}
                setLoading={setLoading}
            />
        </div>
    )
}

export default SelectModel;
