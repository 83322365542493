// import logo from './logo.svg';
import Configurator from './Pages/Configurator/Config'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'
import './App.css';
import Admin from "./Pages/Admin";

function App() {
  return (
      <div className="App">
      <Router>
          <Routes>
              <Route exact path="/" element={<Configurator/>}/>
              <Route exact path="/admin" element={<Admin/>}/>
          </Routes>
      </Router>
      </div>
  );
}

export default App;
