import React from "react";
import * as THREE from "three/examples/jsm/nodes/ShaderNode";
import {DoubleSide} from "three";
import {color} from "three/examples/jsm/nodes/ShaderNode";

const Floor = () => {
    return(
        <>
            <mesh position={[0, 0, 0]} rotation={[Math.PI /2, 0, 0]}>
                <planeGeometry args={[10, 10, 1, 1]}/>
                <meshPhysicalMaterial
                    color={new THREE.color(0x000)}
                    // side={DoubleSide}
                    metalness={1}
                    roughness={0}/>
            </mesh>
        </>
    )
}

export default Floor;
