import React, {useEffect, useState} from 'react';
import './index.scss';
import SelectModel from "../../Components/SelectModel";
import CanvasComponent from "../../Components/Canvas";
import Confirmation from "../../Components/Confirmation";
import BuildComplete from "../../Components/BuldComplete";

const Configurator = () => {
    const [collection, setCollection] = useState('head');
    const [modelsConf, setModelsConf] = useState({
        head: null,
        torso: null,
        arms: null,
        armor: null,
        weapon: null,
    });
    const [dataFetch, setDataFetch] = useState({})

    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState('selectModel');
    return(
        <div className="configurator">
            <div className="configWrap">
                {step === 'selectModel' && <SelectModel
                    setCollection={setCollection}
                    collection={collection}
                    modelsConf={modelsConf}
                    setModelsConf={setModelsConf}
                    setLoading={setLoading}
                    setStep={setStep}
                />}
                {step === 'confirm' && <Confirmation data={dataFetch} modelsConf={modelsConf} setStep={setStep} />}
                {step === 'complete' &&
                    <BuildComplete
                        setStep={setStep}
                        setModelsConf={setModelsConf}
                    />
                }
                <CanvasComponent
                    setDataFetch={setDataFetch}
                    modelsConf={modelsConf}
                    setModelsConf={setModelsConf}
                    step={step}
                    loading={loading}
                    setLoading={setLoading}
                />
            </div>
        </div>
    )
}

export default Configurator;
