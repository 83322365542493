import React, {useEffect, useState} from 'react';
import './index.scss';
import config from "../../config";

const SelectCollection = (
    {
        collection,
        setCollection,
        setStep,
        modelsConf,
        setModelsConf,
    }) => {
    const triangle = document.getElementById('triangle')
    const [finishBuildBtn, setFinishBuildBtn] = useState(false);
    useEffect(() => {
        if (modelsConf.head && modelsConf.torso && modelsConf.arms && modelsConf.armor && modelsConf.weapon) {
            setFinishBuildBtn(true);
        }
    }, [modelsConf])

    useEffect(() => {
        switch (collection) {
            case 'head':
                document.getElementById('triangle').style.transform = 'translateY(66px)'
                break;
            case 'torso':
                document.getElementById('triangle').style.transform = 'translateY(118px)'
                break;
            case 'arms':
                document.getElementById('triangle').style.transform = 'translateY(169px)'
                break;
            case 'armor':
                document.getElementById('triangle').style.transform = 'translateY(220px)'
                break;
            case 'weapons':
                document.getElementById('triangle').style.transform = 'translateY(271px)'
                break;
            default:
                break;
        }
    }, [])
    return(
        <div className="selectCollection">
            <div id='triangle' className="triangle" />
            <div
                className="selectCollection-par">Build your champion</div>
            <div
                className={`selectCollection-par ${collection === 'head' && 'active'}`}
                onClick={() => {
                    setCollection('head')
                    triangle.style.transform = 'translateY(66px)'
                }}
            >
                Head
            </div>
            <div className={`selectCollection-par ${collection === 'torso' && 'active'}`}
                 onClick={() => {
                     setCollection('torso')
                     triangle.style.transform = 'translateY(118px)'
                 }}
            >
                Torso
            </div>
            <div className={`selectCollection-par ${collection === 'arms' && 'active'}`}
                 onClick={() => {
                     setCollection('arms')
                     triangle.style.transform = 'translateY(169px)'
                 }}
            >
                Arms/Legs
            </div>
            <div className={`selectCollection-par ${collection === 'armor' && 'active'}`}
                 onClick={() => {
                     setCollection('armor')
                     triangle.style.transform = 'translateY(220px)'
                 }}
            >
                Armor
            </div>
            <div className={`selectCollection-par ${collection === 'weapons' && 'active'}`}
                 onClick={() => {
                     setCollection('weapons')
                     triangle.style.transform = 'translateY(271px)'
                 }}
            >
                Weapons
            </div>
            <div className="selectCollection-par btn"
            >
                <button className={`addModelBtn ${finishBuildBtn ? 'enable' : 'disable'}`} disabled={!finishBuildBtn} onClick={() => {
                    setStep('confirm');
                }}>
                   Finish my build
                </button>
            </div>
        </div>
    )
}

export default SelectCollection;
