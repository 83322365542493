import React, {useMemo} from "react";
import {useLoader} from "@react-three/fiber";
import { Suspense } from "react";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader";

const HeadLoader = ({setLoading, modelsConf}) => {

    const gltf = useLoader(GLTFLoader, modelsConf.model, loader => {
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('./dracoTwo/');
        loader.setDRACOLoader(dracoLoader);
    });
    if (gltf) {
        setLoading(false)
        gltf.scene.visible = true;
        gltf.scene.children[0].material.color.set(modelsConf.selectColor ? modelsConf.selectColor : modelsConf.color)
    }

    return (
        <Suspense>
            <primitive object={gltf.scene} />
        </Suspense>
    )
}
export default ({ setLoading, modelsConf }) =>
    useMemo(() => <HeadLoader setLoading={setLoading} modelsConf={modelsConf} />, [modelsConf]);
