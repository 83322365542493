import React, {useEffect, useState} from "react";
import './index.scss'
import ButtonLoader from "../ButtonLoader";
// import fetch from "node-fetch";

const Confirmation = ({setStep, modelsConf, data}) => {
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        console.log(modelsConf, 'modelsConf');
    }, [modelsConf]);
     return (
        <div className="confirmation">
            <div className="wrapText">
                <p>
                    The figure you have built is shown on this page
                </p>
                <p>
                    Please make a select from the choices <br/> below to continue
                </p>
            </div>
            <div className="wrapBtn">
                <div className="btn" onClick={ () => {
                    window.open(`https://store-horsemen.myshopify.com/cart/add?id[]=${modelsConf.head.id}&id[]=${modelsConf.torso.id}&id[]=${modelsConf.armor.id}&id[]=${modelsConf.arms.id}&id[]=${modelsConf.weapon.id}`)
                    // const cartUrl = 'https://store-horsemen.myshopify.com/cart/';
                    // const addProductsUrl = `${cartUrl}${modelsConf.head.id}:1,${modelsConf.torso.id}:1,${modelsConf.armor.id}:1,${modelsConf.arms.id}:1,${modelsConf.weapon.id}:1`;
                    // const w1 = window.open(addProductsUrl, '_blank');
                    // // setTimeout(() => w1.close(), 1000);
                    // // setTimeout(() => window.open(cartUrl, '_blank'), 500);
                    //
                    // const a = document.createElement('a');
                    // a.href = 'https://store-horsemen.myshopify.com/cart/';
                    // a.target = '_blank';
                    // setTimeout(() => {
                    //     a.click();
                    // }, 200)

                }}>
                    {loader ? <ButtonLoader /> :"Add figure to cart"}
                </div>
                {/*<div className="btn" onClick={ () => {*/}
                {/*    setLoader(true);*/}
                {/*    fetch('https://fourhorsemen.digitalartflow.net/createProduct', {*/}
                {/*        method: 'POST',*/}
                {/*        headers: {*/}
                {/*            'Content-Type': 'application/json;charset=utf-8',*/}
                {/*        },*/}
                {/*        body: JSON.stringify(data)*/}
                {/*    }).then(() => {*/}
                {/*        fetch('https://fourhorsemen.digitalartflow.net/getId', {*/}
                {/*        // fetch('http://localhost:6606/getId', {*/}
                {/*            method: 'GET',*/}
                {/*        }).then((response) => response.json())*/}
                {/*            .then((json) => {*/}
                {/*                console.log(json);*/}
                {/*                setLoader(false);*/}
                {/*                setStep('complete')*/}
                {/*                window.open(`https://store-horsemen.myshopify.com/cart/add/${json.product.variants[0].id}`, '_blank');*/}
                {/*                // window.open(`https://t3dstoreshopyfy.myshopify.com/cart/add/${json.product.variants[0].id}`, '_blank');*/}
                {/*            });*/}
                {/*    })*/}

                {/*}}>*/}
                {/*    {loader ? <ButtonLoader /> :"Add figure to cart"}*/}
                {/*</div>*/}
                <div className="text">-or-</div>
                <div className="btn second" onClick={() => {
                    setStep('selectModel')
                }}>Make changes to figure</div>
            </div>
        </div>
    )
}

export default Confirmation;
