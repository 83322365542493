import {extend, useFrame, useThree} from "@react-three/fiber";
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'
import * as THREE from "three";
import React, {useEffect, useMemo, useRef} from "react";
import { Suspense } from "react";
import HeadLoader from "../HeadLoader";
import TorsoLoader from "../TorsoLoader";
import ArmsLoader from "../ArmsLoader";
import ArmorLoader from "../ArmorLoader";
import Floor from "../Floor";
import WeaponLoader from "../WeaponLoader";
extend({OrbitControls})

const Controls = () => {
    const controls = useRef()
    const { camera, gl } = useThree()
    useEffect(
        () => {
            const controls = new OrbitControls(camera, gl.domElement);
            controls.object.position.set(0.01, 1.35, 1.35)
            controls.maxDistance = 3;
            controls.minDistance = 1.9;
            // controls.enablePan = false;
            controls.rotateSpeed = 0.1
            controls.update();
            return () => {
                controls.dispose();
            };
        },
        [camera, gl]
    );
    useFrame(() => controls.current.update())
    return <orbitControls
        ref={controls}
        args={[camera, gl.domElement]}
        enableDamping
        maxPolarAngle={1.7}
        minPolarAngle={0.5}
        enablePan
        dampingFactor={0.1}
        target={[0, 1.2, 0]}
    />
}

const Scene = ({setLoading, modelsConf}) => {
    const light = useMemo(() => new THREE.DirectionalLight(0xffffff, 1.2), [])
    const {
        scene, camera, gl: {domElement},
    } = useThree();
    useEffect(() => {
        scene.background = new THREE.Color(0x000000);
        scene.fog = new THREE.Fog(0xf1f1f1, 0.1, 100);
        camera.fov = 50;
    }, [])
    return (
        <>
            <Controls />
            <primitive object={light} position={[0, 50, 5]} />
            <primitive object={light.target} position={[0, -30, -100]}  />

            <directionalLight
                color={new THREE.Color(0xffffff)}
                intensity={0.9}
                position={[-9, 5, -2]}
            />
            <directionalLight
                color={new THREE.Color(0xffffff)}
                intensity={0.9}
                position={[-9, 8, 8]}
            />

            <directionalLight
                color={new THREE.Color(0xffffff)}
                intensity={0.6}
                position={[9, 3, -3]}
            />
            <directionalLight
                color={new THREE.Color(0xffffff)}
                intensity={0.9}
                position={[9, 8, 8]}
            />

            <directionalLight
                color={new THREE.Color(0xffffff)}
                intensity={0.8}
                position={[2, 5, 4]}
            />
            <directionalLight
                color={new THREE.Color(0xffffff)}
                intensity={0.9}
                position={[0, 0, -8]}
                // target={{}}
            />
            <Floor />
            <Suspense>
                {modelsConf.head &&
                    <Suspense>
                        <HeadLoader setLoading={setLoading} modelsConf={modelsConf.head} />
                    </Suspense>
                }
                {modelsConf.torso &&
                    <Suspense>
                        <TorsoLoader setLoading={setLoading} modelsConf={modelsConf.torso}/>
                    </Suspense>
                }
                {modelsConf.arms &&
                    <Suspense>
                        <ArmsLoader setLoading={setLoading} modelsConf={modelsConf.arms}/>
                    </Suspense>
                }
                {modelsConf.armor &&
                    <Suspense>
                        <ArmorLoader setLoading={setLoading} modelsConf={modelsConf.armor}/>
                    </Suspense>
                }
                {modelsConf.weapon &&
                    <Suspense>
                        <WeaponLoader setLoading={setLoading} modelsConf={modelsConf.weapon}/>
                    </Suspense>
                }
            </Suspense>
        </>
    )
}

export default Scene;
