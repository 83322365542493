import React from "react";
import './index.scss'
const ButtonLoader = () => {
    return (
        <div className="loader-container">
            <div className="loader" />
        </div>
    );
}

export default ButtonLoader;
