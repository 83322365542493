import React, {useEffect, useState} from "react";
import { SketchPicker } from 'react-color';
import './index.scss';
import axios from "axios";
import config from "../../../config";

const Uploader = ({collection, setList}) => {
    const [cutout, setCutout] = useState(false);
    const [picker, setPicker] = useState(false);
    const [colorHexCode, setColorHexCode] = useState('#000000');
    const [newFile, setNewFile] = useState({
        name: '',
        model: null,
        thumbnail: null,
        color: null,
        stock: null,
        id: null,
    })

    useEffect(() => {
        setNewFile((prevState) => ({
            ...prevState,
            name: collection,
            color: colorHexCode,
        }))
    }, [collection, cutout]);

    const clearInputValue = () => {
        document.getElementById('3dFile').value = "";
        document.getElementById('thumbnail').value = "";
        document.getElementById('stock').value = "";
        setColorHexCode('#000000');
        setCutout(!cutout)
        setNewFile((prevState) => ({
            ...prevState,
            name: null,
            model: null,
            thumbnail: null,
            color: null,
            stock: null,
            id: null,
        }))
    }

    return (
        <div className="uploader">
            <div className="upload3D">
                <div>Select 3D file <span className="modelFormat">(.glb, .gltf)</span></div>
                <input
                    id="3dFile"
                    name="file"
                    type="file"
                    accept=".glb, .gltf"
                    onChange={(e) => {
                        setNewFile((prevState) => ({
                            ...prevState,
                            model: e.target.files[0]
                    }))
                }} />
            </div>
            <div className="uploadImage">
                <div>Select thumbnail <span className="modelFormat">(.jpg, .png, .svg)</span></div>
                <input
                    id="thumbnail"
                    type="file"
                    accept=".jpg, .jpeg, .png, .svg"
                    onChange={(e) => {
                        setNewFile((prevState) => ({
                        ...prevState,
                        thumbnail: e.target.files[0]
                    }))
                }} />
            </div>
            <div className="selectColor">
                <div className="title">Select color</div>
                <div
                    style={{background: colorHexCode}}
                    className={`color`}
                    onClick={() => {
                        setPicker(true);
                    }}
                />
                <div className={`colorPicker ${picker ? 'active' : ''}`}>
                    <div className="close" onClick={() => {
                        setPicker(false)
                    }}/>
                    <SketchPicker
                        color={colorHexCode}
                        onChange={(e) => {
                        setColorHexCode(e.hex)
                        setNewFile((prevState) => ({
                            ...prevState,
                            color: e.hex,
                        }))
                    }} />
                </div>
            </div>
            <div className="selectId">
                <div className="title">Select id</div>
                <input
                    id="id"
                    type="number"
                    onChange={(e) => {
                        setNewFile((prevState) => ({
                            ...prevState,
                            id: e.target.value
                        }))
                    }} />
            </div>
            <div className="stock">
                <div className="title">Stock</div>
                <input
                    id="stock"
                    type='number'
                    onChange={(e) => {
                        setNewFile((prevState) => ({
                            ...prevState,
                            stock: e.target.value
                        }))
                    }}
                />
            </div>
            <div
                className="uploadBtn"
                onClick={() => {
                    if (newFile.color === null) {
                        setNewFile((prevState) => ({
                            ...prevState,
                            color: '#000000',
                        }))
                    }
                    const formData = new FormData()
                    formData.append('name', newFile.name);
                    formData.append('model', newFile.model);
                    formData.append('thumbnail', newFile.thumbnail);
                    formData.append('color', newFile.color);
                    formData.append('stock', newFile.stock);
                    formData.append('id', newFile.id);
                    // console.log(newFile)
                    axios.post(`${config.SERVER_ADDRESS}/api/posts`, formData)
                        .then((response) => {
                            clearInputValue()
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                        .then(() => {
                            axios.get(`${config.SERVER_ADDRESS}/api/posts`)
                                .then((response) => {
                                    setList(response.data);
                                })
                                .catch((error) => {
                                    console.log(error);
                                })
                                .then(() => {});
                        });
                }}
            >
                Upload
            </div>
        </div>
    )
}

export default Uploader;
