import React from "react";
import './index.scss';

const ConfirmDelete = ({setConfirmDel, deleteAll}) => {
    return(
        <div className="confirmDelete">
            <div className="close" onClick={() => {
                setConfirmDel(false);
            }} />
            <div className="content">
                Are you sure you want to&nbsp; <br/><span className="mark">delete</span>&nbsp;models?
            </div>
            <div className="buttonWrap">
                <div className="btn cancel" onClick={() => {
                    setConfirmDel(false);
                }}>Cancel</div>
                <div className="btn delete" onClick={() => {
                    deleteAll()
                    setConfirmDel(false);
                }}>Delete</div>
            </div>
        </div>
    )
}

export default ConfirmDelete;
