import React, {useEffect, useState} from 'react';
import './index.scss'
import config from '../../config';
import Spinner from "../Spinner";

const SelectedCollection = (
    {
        collection,
        setLoading,
        setModelsConf,
        newModels,
        modelsConf,
        setPreviewLoad,
        previewLoad,
    }
) => {
    const [headActiveIndex, setHeadActiveIndex] = useState();
    const [torsoActiveIndex, setTorsoActiveIndex] = useState();
    const [armsActiveIndex, setArmsActiveIndex] = useState();
    const [armorActiveIndex, setArmorActiveIndex] = useState();
    const [weaponActiveIndex, setWeaponActiveIndex] = useState();

    useEffect(() => {
        // console.log(newModels, 'newModels');
    }, [newModels]);

    const isClothesActive = (index) => {
        switch (collection) {
            case 'head': return headActiveIndex === index;
            case 'torso': return torsoActiveIndex === index;
            case 'arms':return armsActiveIndex === index;
            case 'armor': return armorActiveIndex === index;
            case 'weapons': return weaponActiveIndex === index;
            default:return false;
        }
    }

    return (
        <div className="selectedCollection">
            <div className="arrowTop" onClick={() => {
                document.getElementById('preview').scrollTop -= 100;
            }}>
                <div className="triangle" />
            </div>
            <div className="arrowBottom" onClick={() => {
                document.getElementById('preview').scrollTop += 100;
            }}>
                <div className="triangle" />
            </div>
            <div className="borderTop" />
            <div className="preview" id="preview">
                {
                    previewLoad && <Spinner />
                }

                <ul>
                    {newModels.map((val, index) => (
                        <div className={isClothesActive(index) ? 'active' : ''} key={index} onClick={() => {
                            switch (collection) {
                                case 'head':
                                    if (modelsConf.head !== `${config.SERVER_ADDRESS + '/' + val.model[0]}` && val.stock > 0 ) {
                                        // console.log(val, 'val');
                                        setHeadActiveIndex(index);
                                        setModelsConf(prevState=>({
                                            ...prevState,
                                            head: {
                                                ...prevState.head,
                                                model: `${config.SERVER_ADDRESS + '/' + val.model[0]}`,
                                                color: val.color,
                                                name: val.model[1],
                                                id: val.id,
                                            },
                                        }))
                                        setLoading(true);
                                    }
                                    break;
                                case 'torso':
                                    if (modelsConf.torso !== `${config.SERVER_ADDRESS + '/' + val.model[0]}` && val.stock > 0) {
                                        // console.log(val, 'val');
                                        setTorsoActiveIndex(index);
                                        setModelsConf(prevState=>({
                                            ...prevState,
                                            torso: {
                                                ...prevState.torso,
                                                model: `${config.SERVER_ADDRESS + '/' + val.model[0]}`,
                                                color: val.color,
                                                name: val.model[1],
                                                id: val.id,
                                            },
                                        }))
                                        setLoading(true);
                                    }
                                    break;
                                case 'arms':
                                    if (modelsConf.arms !== `${config.SERVER_ADDRESS + '/' + val.model[0]}` && val.stock > 0) {
                                        setArmsActiveIndex(index)
                                        setModelsConf(prevState=>({
                                            ...prevState,
                                            arms: {
                                                ...prevState.arms,
                                                model: `${config.SERVER_ADDRESS + '/' + val.model[0]}`,
                                                color: val.color,
                                                name: val.model[1],
                                                id: val.id,
                                            },
                                        }))
                                        setLoading(true);
                                    }
                                    break;
                                case 'armor':
                                    if (modelsConf.armor !== `${config.SERVER_ADDRESS + '/' + val.model[0]}` && val.stock > 0) {
                                        setArmorActiveIndex(index)
                                        setModelsConf(prevState=>({
                                            ...prevState,
                                            armor: {
                                                ...prevState.armor,
                                                model: `${config.SERVER_ADDRESS + '/' + val.model[0]}`,
                                                color: val.color,
                                                name: val.model[1],
                                                id: val.id,
                                            },
                                        }))
                                        setLoading(true);
                                    }
                                    break;
                                case 'weapons':
                                    if (modelsConf.weapon !== `${config.SERVER_ADDRESS + '/' + val.model[0]}` && val.stock > 0) {
                                        setWeaponActiveIndex(index)
                                        setModelsConf(prevState=>({
                                            ...prevState,
                                            weapon: {
                                                ...prevState.weapon,
                                                model: `${config.SERVER_ADDRESS + '/' + val.model[0]}`,
                                                color: val.color,
                                                name: val.model[1],
                                                id: val.id,
                                            },
                                        }))
                                        setLoading(true);
                                    }
                                    break;
                                default:
                                   console.log('<<')
                                    break;
                            }
                        }}
                        >
                            {
                                <img
                                    className={`${val.stock <= 0 && 'missing'}`}
                                    src={`${config.SERVER_ADDRESS + '/' + val.thumbnail[0]}`}
                                     onLoad={() => {
                                         setPreviewLoad(false)}
                                     }
                                />
                            }
                        </div>
                    ))}
                </ul>
            </div>
            <div className="borderBottom" />
        </div>
    )
}

export default SelectedCollection;
