import React from "react";
import './index.scss'

const BuildComplete = ({setStep, setModelsConf}) => {
    return (
        <div className="complete">
            <div className="wrapText">
                <p>
                    Congratulations! Your warrior has been <br/> added to your cart!
                </p>
                <p>
                    What would you like to do next?
                </p>
            </div>
            <div className="wrapBtn">
                <div className="btn first" onClick={() => {
                    setModelsConf((prevState => ({
                        ...prevState,
                        head: null,
                        torso: null,
                        arms: null,
                        armor: null,
                        weapon: null,
                    })))
                    setStep('selectModel')
                }}>Build another figure </div>
                <div className="text">-or-</div>
                {/*<div className="btn second">View cart and checkout</div>*/}
                <a href="https://store-horsemen.myshopify.com/cart"
                    className="btn second"
                    target="_blank"
                   onClick={() => {
                   }}
                >
                    View cart and checkout
                </a>
            </div>
        </div>
    )
}

export default BuildComplete;
