import React, {useState} from "react";
import './index.scss';
import Collection from "../../Components/Admin/Collections";
import Uploader from "../../Components/Admin/Uploader";
import Catalog from "../../Components/Admin/Catalog";

const Admin = () => {
    const [collection, setCollection] = useState('head');
    const [list, setList] = useState();
    return(
        <div className="adminPage">
            <div className="header">
                <div>3D Uploader</div>
                <div>Admin user</div>
            </div>
            <div className="mainWrapper">
                <Collection
                    collection={collection}
                    setCollection={setCollection}
                />
                <div className="uploaderWrapper">
                    <Uploader collection={collection} setList={setList} />
                    <Catalog collection={collection} list={list} setList={setList}/>
                </div>
            </div>
        </div>
    )
}

export default Admin;
