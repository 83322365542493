import React from "react";
import './index.scss'
const Spinner = () => {
    return (
        <div className="spinner-container">
            <div className="loading-spinner" />
        </div>
    );
}

export default Spinner;
