import React, {useEffect, useRef, useState} from 'react';
import {Canvas, useThree} from "@react-three/fiber";
import './index.scss';
import Scene from "../Scene";
import Spinner from "../Spinner";
import { Suspense } from "react";
import config from "../../config";

const CanvasComponent = ({loading, setLoading, modelsConf, setModelsConf, step, setDataFetch}) => {
    const canvas = useRef()

    useEffect(() => {
        if (step === 'confirm') {
            // console.log(modelsConf, 'canv')
            downloadCanvas()
        }
    }, [step]);

    const downloadCanvas = () => {
        const canvasCur = canvas.current;
        let tempCanvas = document.createElement("canvas");
        tempCanvas.width = 600;
        tempCanvas.height = 600;
        let tempContext = tempCanvas.getContext("2d");
        tempContext.drawImage(canvasCur, 0, 0, tempCanvas.width, tempCanvas.height)
        const downloadImg = tempCanvas.toDataURL('image/jpeg');
        const strImage = downloadImg.replace(/^data:image\/[a-z]+;base64,/, "");
        setDataFetch({
            product: {
                title: "Horsemen",
                body_html: "<strong>Horsemen champion!</strong>",
                vendor: "Store Horsemen",
                product_type: "Horsemen",
                tags: ['Horsemen'],
                image: strImage,
                taxable: true,
                status: "active",
                // published: false,
                // published_scope: "global",
                metafield: {
                    namespace: "seo",
                    key: "hidden",
                    value: 1,
                    type: "number_integer"
                },
                options:[
                    {
                        name: "Head/Torso",
                        position: 0,
                        values: [
                            `Head: Name/Color: ${modelsConf.head.name}/${modelsConf.head.color}, Torso Name/Color: ${modelsConf.torso.name}/${modelsConf.torso.color}`,
                        ]
                    },
                    {
                        name: "Arms/Legs",
                        position: 0,
                        values: [
                            `Arms/Legs: Name/Color: ${modelsConf.arms.name}/${modelsConf.arms.color}`
                        ]
                    },
                    {
                        name: "Armor/Weapon",
                        position: 0,
                        values: [
                            `Armor: Name/Color: ${modelsConf.armor.name}/${modelsConf.armor.color}, Weapon Name/Color: ${modelsConf.weapon.name}/${modelsConf.weapon.color}`
                        ]
                    },
                ],
                variants:[
                    {
                        // "sku":"D1030Q02024T_middle-green_L",
                        title:"Horsemen",
                        inventory_management: "shopify",
                        inventory_policy: "continue",
                        inventory_quantity: 10,
                        requires_shipping: true,
                        taxable: true,
                        price:"25.00",
                        presentment_prices:{
                            compare_at_price:{
                                amount:"25.00",
                                currency_code:"USD"
                            }
                        },
                        // "weight":"700",
                        // "weight_unit":"",
                        option1: `Head: ${modelsConf.head.name.slice(0, -4)}/${modelsConf.head.color}, Torso: ${modelsConf.torso.name.slice(0, -4)}/${modelsConf.torso.color}`,
                        option2: `${modelsConf.arms.name.slice(0, -4)}/${modelsConf.arms.color}`,
                        option3: `Armor: ${modelsConf.armor.name.slice(0, -4)}/${modelsConf.armor.color}, Weapon: ${modelsConf.weapon.name.slice(0, -4)}/${modelsConf.weapon.color}`,
                    },
                ],
            }
        })
    }

    return(
        <div className="canvasComponent">
            {
                loading && <Spinner />
            }
            <Suspense>
                <Canvas ref={canvas} gl={{ preserveDrawingBuffer: true }}  id='canvas' className="canvas">
                    <Scene
                        modelsConf={modelsConf}
                        setLoading={setLoading}
                    />
                </Canvas>

            </Suspense>
        </div>
    )
}

export default CanvasComponent;
