import React from "react";
import './index.scss';

const Collection = ({collection, setCollection}) => {
    return(
        <div className="collection">
            <div
                className={`collection-par first ${collection === 'head' && 'active'}`}
                onClick={() => {
                    setCollection('head')
                }}
            >
                Head
            </div>
            <div className={`collection-par ${collection === 'torso' && 'active'}`}
                 onClick={() => {
                     setCollection('torso')
                 }}
            >
                Torso
            </div>
            <div className={`collection-par ${collection === 'arms' && 'active'}`}
                 onClick={() => {
                     setCollection('arms')
                 }}
            >
                Arms/Legs
            </div>
            <div className={`collection-par ${collection === 'armor' && 'active'}`}
                 onClick={() => {
                     setCollection('armor')
                 }}
            >
                Armor
            </div>
            <div className={`collection-par ${collection === 'weapons' && 'active'}`}
                 onClick={() => {
                     setCollection('weapons')
                 }}
            >
                Weapons
            </div>
        </div>
    )
}

export default Collection;
